<template>
  <section>
    <h1>{{ $t('security.forgottenLogin.title') }}</h1>
    <p>{{ $t('security.forgottenLogin.message') }}</p>
    <v-form>
      <v-text-field
        v-model="email"
        :label="$t('security.forgottenLogin.inputs.email')"
        outlined
      />
      <v-select
        v-model="study"
        :label="$t('navbar.selects.study')"
        :items="this.studiesLocale"
        solo
        dense
      />
      <v-btn type="submit" color="primary" @click.prevent="onSubmitForm"
        >{{ $t('security.forgottenLogin.button') }}
      </v-btn>
    </v-form>
  </section>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'RecoveryLogin',

  data: () => ({
    email: '',
    studiesLocale: [],
    study: '',
  }),

  methods: {
    ...mapActions(['recoveryLogin', 'loadStudies']),
    onSubmitForm() {
      if (this.email) {
        this.recoveryLogin({ email: this.email, study: this.study })
      }
    },
  },

  computed: {
    ...mapGetters(['studies']),
  },

  created() {
    this.loading = true
    Promise.all([this.loadStudies()]).then(() => {
      this.studies.forEach(study => {
        this.studiesLocale.push({
          value: study.id,
          text: study.label,
        })
      })

      this.loading = false
    })
  },
}
</script>

<style lang="scss" scoped>
@import '../../assets/style/backgroundPage';
</style>
